<template>
  <div class="wrapper">
    <div class="madeby">
      <img src="../../assets/yanniksimon.png" alt="" />
      <p>Made by <a href="https://www.yanniksimon.de/">yanniksimon.de</a></p>
    </div>

    <div class="support">
      <p>Bei Fragen, Problemen oder Feedback</p>
      <a href="mailto:support@einladung.app">support@einladung.app</a>
    </div>

    <div class="links">
      <router-link to="/impressum">Impressum</router-link>
      <router-link to="/agb">AGB</router-link>

      <a href="https://www.iubenda.com/privacy-policy/72827300"
        >Datenschutzerklärung</a
      >
      <a href="https://www.iubenda.com/privacy-policy/72827300/cookie-policy"
        >Cookie Richtlinie</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {},
};
</script>

<style scoped>
.wrapper {
  background-color: var(--dark-blue) !important;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  font-size: 0.9rem;
  padding: 32px 0 !important;
}

.madeby {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.links {
  display: flex;
  flex-direction: column;
}

a {
  color: white;
}

img {
  width: clamp(40px, 10vw, 60px);
  background-color: white;
  border-radius: 50%;
  transform: scale(-1, 1);
}

@media screen and (max-width: 900px) {
  .wrapper {
    grid-template-columns: 1fr;
    gap: 24px;
    text-align: center;
  }
}
</style>