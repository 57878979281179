<template>
  <div class="container">
    <div class="event" @click="openEventOverview()">
      <h3 class="title">{{ event.title }}</h3>
      <div class="info attendees">
        <font-awesome-icon class="icon" icon="user" />
        {{ numberOfAttendees }}
      </div>
      <div class="info time">
        <font-awesome-icon class="icon" icon="clock" />
        {{ event.time }}
      </div>
      <div class="info date">
        <font-awesome-icon class="icon" icon="calendar" />
        {{ dateFormatter }}
      </div>
      <font-awesome-icon class="icon chevron" icon="chevron-right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EventListItem",
  props: {
    event,
  },
  data() {
    return {
      formattedDate: null,
    };
  },
  methods: {
    openEventOverview: function () {
      this.$router.push(`/organizer/event/${this.event._id}`);
    },
  },
  computed: {
    dateFormatter: function () {
      const date = new Date(this.event.date);
      return date.toLocaleDateString();
    },

    numberOfAttendees: function () {
      return this.event.attendees.length;
    },
  },
};
</script>

<style scoped>
.event {
  display: grid;
  grid-template-areas: "title attendees time date . chevron";
  grid-template-columns: 6fr 40px 70px 100px 1fr 10px;
  gap: 24px;
  align-items: center;
  background-color: var(--gray-200);
  border-radius: 7px;
  padding: 24px 24px 24px 12px;
  margin-bottom: 12px;
  cursor: pointer;
}

.event:hover {
  background-color: var(--gray-200);
}

.info {
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
}

.icon {
  margin: 0 8px 0 0;
  font-size: 1rem;
}

.title {
  grid-area: title;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.attendees {
  grid-area: attendees;
}
.time {
  grid-area: time;
}

.date {
  grid-area: date;
}
.chevron {
  grid-area: chevron;
}

@media screen and (max-width: 601px) {
  .event {
    grid-template-areas:
      "title title title title chevron"
      "attendees time date . chevron";
    grid-template-columns: 40px 70px 100px 1fr 20px;
    gap: 8px;
    padding: 12px 24px;
    font-size: 1rem;
  }

  .title {
    font-size: 1.4rem;
    margin-bottom: 8px;
  }
}
</style>