<template>
  <div class="nav">
    <div class="logo">
      <p
        v-if="
          pathName == 'landingpage' ||
          pathName == 'login' ||
          pathName == 'signup'
        "
        @click="$router.push('/')"
        class="font"
      >
        <font-awesome-icon class="icon yellow" icon="paper-plane" />
        einladung<span class="yellow">.</span>app
      </p>

      <p
        v-else-if="pathName == 'event'"
        @click="openLandingPage()"
        class="font"
      >
        <font-awesome-icon class="icon yellow" icon="paper-plane" />
        einladung<span class="yellow">.</span>app
      </p>

      <p v-else @click="$router.push('/organizer/overview')" class="font">
        <font-awesome-icon class="icon yellow" icon="paper-plane" />
        einladung<span class="yellow">.</span>app
      </p>
    </div>

    <div class="links">
      <p v-if="pathName == 'landingpage'" @click="$router.push('/login')">
        Login
      </p>
      <p
        class="logout"
        v-if="
          pathName != 'landingpage' &&
          pathName != 'signup' &&
          pathName != 'login' &&
          pathName != 'event'
        "
        @click="$router.push('/logout')"
      >
        Logout
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      pathName: this.$route.name,
    };
  },
  methods: {
    openLandingPage() {
      window.location.href = "https://einladung.app";
    },
  },
  mounted() {},
};
</script>

<style scoped>
.nav {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 16px;
  grid-area: nav;
  background-color: var(--dark-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav .logo .font {
  font-family: "Roboto Mono", monospace;
  font-size: 1.5rem;
  margin: 0;
}
router-link {
  color: var(--dark-blue);
  text-decoration: none;
}
.yellow {
  color: var(--yellow);
}

.logo .icon {
  margin-right: 6px;
}

.links {
  display: flex;
  align-items: center;
  color: white;
}

.links > * {
  margin: 0 12px;
  cursor: pointer;
  text-transform: uppercase;
}

.logout {
  cursor: pointer;
  margin: 0 12px;
}
</style>