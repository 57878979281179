<template>
  <div>
    <Nav></Nav>

    <div class="wrapper">
      <div class="headline">
        <h1>Veranstaltung erstellen</h1>
        <h2>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium
          reiciendis, voluptates veniam temporibus ipsam nostrum eveniet
          reprehenderit. Deleniti, iusto distinctio!
        </h2>
      </div>

      <div class="content">
        <h2>Allgemein</h2>
        <ValidationObserver v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit(createEvent)">
            <ValidationProvider
              mode="aggressive"
              rules="min:4"
              v-slot="{ errors }"
            >
              <p class="error" v-if="errors.length != 0">
                <font-awesome-icon icon="exclamation-triangle" />
                {{ errors[0] }}
              </p>
              <p class="title" v-else>Titel</p>
              <input
                type="text"
                placeholder="Mein Event"
                v-model="title"
                name="Titel"
              />
            </ValidationProvider>

            <ValidationProvider
              mode="aggressive"
              rules="subdomain|min:2"
              v-slot="{ errors }"
            >
              <p class="error" v-if="errors.length != 0">
                <font-awesome-icon icon="exclamation-triangle" />
                {{ errors[0] }}
              </p>
              <p class="title" v-else>Subdomain</p>
              <div class="subdomain">
                <input
                  class="subdomain"
                  type="text"
                  placeholder="meinevent"
                  v-model="subdomain"
                  name="Subdomain"
                />
                <span class="domain">.einladung.app</span>
              </div>
            </ValidationProvider>

            <ValidationProvider
              mode="aggressive"
              rules="min:4"
              v-slot="{ errors }"
            >
              <p class="error" v-if="errors.length != 0">
                <font-awesome-icon icon="exclamation-triangle" />
                {{ errors[0] }}
              </p>
              <p class="title" v-else>Beschreibung</p>
              <textarea
                type="text"
                rows="4"
                placeholder="Beschreibung"
                v-model="description"
                name="Beschreibung"
              ></textarea>
            </ValidationProvider>

            <h2>Wann</h2>
            <div class="container">
              <ValidationProvider
                mode="aggressive"
                rules="required"
                v-slot="{ errors }"
              >
                <p class="error" v-if="errors.length != 0">
                  <font-awesome-icon icon="exclamation-triangle" />
                  {{ errors[0] }}
                </p>
                <p class="title" v-else>Uhrzeit</p>

                <input type="time" v-model="time" name="Zeit" />
              </ValidationProvider>

              <ValidationProvider
                mode="aggressive"
                rules="required"
                v-slot="{ errors }"
              >
                <p class="error" v-if="errors.length != 0">
                  <font-awesome-icon icon="exclamation-triangle" />
                  {{ errors[0] }}
                </p>
                <p class="title" v-else>Datum</p>

                <input type="date" v-model="date" name="Datum" />
              </ValidationProvider>
            </div>

            <h2>Adresse</h2>
            <div class="container">
              <ValidationProvider
                mode="aggressive"
                rules="required|alpha"
                v-slot="{ errors }"
              >
                <p class="error" v-if="errors.length != 0">
                  <font-awesome-icon icon="exclamation-triangle" />
                  {{ errors[0] }}
                </p>
                <p class="title" v-else>Adresse</p>
                <input
                  type="text"
                  placeholder="Straße"
                  v-model="street"
                  name="Straße"
                />
              </ValidationProvider>

              <ValidationProvider
                mode="aggressive"
                rules="required"
                v-slot="{ errors }"
              >
                <p class="error" v-if="errors.length != 0">
                  <font-awesome-icon icon="exclamation-triangle" />
                  {{ errors[0] }}
                </p>
                <p class="title" v-else>Hausnummer</p>
                <input
                  type="text"
                  placeholder="Hausnr."
                  v-model="number"
                  name="Hausnummer"
                />
              </ValidationProvider>
            </div>
            <div class="container">
              <ValidationProvider
                mode="aggressive"
                rules="required|numeric|min:5|max:5"
                v-slot="{ errors }"
              >
                <p class="error" v-if="errors.length != 0">
                  <font-awesome-icon icon="exclamation-triangle" />
                  {{ errors[0] }}
                </p>
                <p class="title" v-else>ZIP</p>
                <input type="text" placeholder="ZIP" v-model="zip" name="ZIP" />
              </ValidationProvider>
              <ValidationProvider
                mode="aggressive"
                rules="required|alpha"
                v-slot="{ errors }"
              >
                <p class="error" v-if="errors.length != 0">
                  <font-awesome-icon icon="exclamation-triangle" />
                  {{ errors[0] }}
                </p>
                <p class="title" v-else>Stadt</p>
                <input
                  type="text"
                  placeholder="Stadt"
                  v-model="city"
                  name="Stadt"
                />
              </ValidationProvider>
            </div>

            <h2>Eintritt</h2>
            <ValidationProvider
              mode="aggressive"
              rules="required|double:2,comma"
              v-slot="{ errors }"
            >
              <p class="error" v-if="errors.length != 0">
                <font-awesome-icon icon="exclamation-triangle" />
                {{ errors[0] }}
              </p>
              <input
                type="text"
                placeholder="25€"
                v-model="price"
                name="Preis"
              /> </ValidationProvider
            ><br />

            <p class="error" v-if="errorResponse">
              <font-awesome-icon icon="exclamation-triangle" />
              {{ errorResponse }}
            </p>

            <button
              :disabled="invalid"
              :class="{ disabled: invalid }"
              type="submit"
            >
              Erstellen
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Nav from "../../components/Nav";

export default {
  name: "CreateEvent",
  components: {
    Nav,
  },
  data() {
    return {
      title: null,
      subdomain: null,
      description: null,
      time: null,
      date: null,
      street: null,
      number: null,
      city: null,
      zip: null,
      price: null,
      errorResponse: null,
    };
  },
  methods: {
    createEvent: function () {
      axios
        .request({
          url: `${process.env.VUE_APP_BACKEND_URL}/api/events/`,
          method: "post",
          data: {
            title: this.title,
            subdomain: this.subdomain,
            description: this.description,
            time: this.time,
            date: this.date,
            address: {
              street: this.street,
              number: this.number,
              city: this.city,
              zip: this.zip,
            },
            price: this.price,
          },
        })
        .then(() => {
          this.trackEvent();
          this.$router.push("/organizer/overview");
        })
        .catch((error) => {
          this.errorResponse = error.response.data.message;
          if (error.response.status == 403) {
            this.errorResponse = error.response.data;
          }
          console.log(error.response.data);
        });
    },
    trackEvent: function () {
      this.$gtag.event("create_event", {
        event_category: "event",
        value: this.name,
      });
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 10px 0 2px 0;
}

.wrapper {
  color: white;
  background-color: var(--yellow);
}

input,
button,
textarea {
  margin: 8px 0;
}

button {
  width: 100%;
}

.disabled {
  background-color: var(--dark-blue-300);
  color: var(--dark-blue-200);
}

.subdomain {
  display: flex;
  align-items: center;
}

.error,
.title {
  margin: 8px 0 0 0;
}

.domain {
  margin: 0 0 0 12px;
}

@media screen and (min-width: 600px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .container > * {
  }
}
</style>