import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import { routes } from "./js/routes"
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate';
import { required, email, min, max, numeric, alpha, double } from 'vee-validate/dist/rules';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faPaperPlane, faArrowLeft, faArrowRight, faTimes, faCheck, faPlus, faExclamationTriangle, faExternalLinkAlt, faQuestionCircle, faChevronDown, faChevronRight, faMoneyBillWaveAlt, faCoins, faGlobeEurope, faClock, faPaintBrush, faUsers, faCalendarAlt, faClipboardList, faUser, faCalendar, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faCcStripe } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGtag from "vue-gtag";
import axios from 'axios';
import VueConfetti from 'vue-confetti'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.use(VueConfetti)

Vue.use(axios)
axios.defaults.withCredentials = true;

//FontAwesome
library.add(faUserSecret, faPaperPlane, faArrowLeft, faArrowRight, faTimes, faCheck, faPlus, faGlobeEurope, faExclamationTriangle, faExternalLinkAlt, faQuestionCircle, faChevronDown, faChevronRight, faMoneyBillWaveAlt, faCcStripe, faCoins, faGlobeEurope, faClock, faPaintBrush, faUsers, faCalendarAlt, faClipboardList, faUser, faCalendar, faMapMarkerAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// No message specified.
extend('email', email)
extend("numeric", numeric)
extend("min", {
  ...min,
  message: "{_field_} muss mindestens {length} Zeichen lang sein."
})
extend("max", {
  ...max,
  message: "{_field_} darf maximal {length} Zeichen lang sein."
})
extend('required', {
  ...required,
  message: 'Pflichtfeld'
});

extend('alpha', {
  ...alpha,
  message: 'Nur Buchstaben erlaubt'
});

extend('double', {
  ...double,
  message: 'Nur runde Zahlen oder Zahlen mit zwei Nachkommastellen, erlaubt'
});



extend("subdomain", value => {
  const subRegex = /^[a-z0-9]*$/
  if (subRegex.test(value)) {
    return true
  }

  return "Nur Kleinbuchstaben und Zahlen erlaubt (keine Leerzeichen)"
})

setInteractionMode("eager")

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.config.productionTip = false

//Vue Router
Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  mode: "history"
})

//Analytics
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_TRACKING_ID }
}, router);

new Vue({
  render: h => h(App),
  router,
  mounted() {
    AOS.init()
  },
}).$mount('#app')
