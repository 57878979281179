<template>
  <div class="wrapper">
    <div class="content">
      <div class="headline">
        <h1>
          Dein Ticket-Service für
          <span class="yellow">Liveerlebnisse</span>!
        </h1>
        <h2>
          Jetzt
          <span class="yellow">Veranstaltungen</span> erstellen, und Umsätze
          generieren.
        </h2>
        <button>
          <router-link to="/signup">
            Jetzt Veranstaltung erstellen!</router-link
          >
        </button>
      </div>
      <font-awesome-icon class="icon" icon="chevron-down" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  color: white;
  grid-area: hero;
}

.wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/hero.jpg");
  filter: grayscale(100%);
  background-size: cover;
  background-position: center;
}

.headline {
  font-size: 1.7rem;
}

.headline h2 {
  text-transform: uppercase;
}

.yellow {
  color: var(--yellow);
}

.content {
  position: relative;
  height: 100%;
  text-align: center;
  display: grid;
  grid-template-rows: 6fr 1fr;
  align-items: center;
  justify-items: center;
}

.content .top > * {
  margin: 24px 0;
}

.wrapper h1 {
  text-transform: uppercase;
}

.wrapper h2 {
  margin: 18px 0;
}

h1,
h2 {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.wrapper button {
  color: white;
  background-color: var(--yellow);
  border: none;
  padding: 14px 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 4px;
  height: auto;
}

.wrapper a {
  color: white;
  text-decoration: none;
}

.wrapper button:hover {
  background-color: var(--yellow-200);
}

button {
  margin: 24px 0 0 0;
}

@media screen and (max-width: 900px) {
  .headline {
    font-size: 1.2rem;
  }
}
</style>