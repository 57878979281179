<template>
  <div>
    <Nav></Nav>
    <div class="wrapper">
      <div class="headline">
        <h1>Erstelle einen Account</h1>
        <h2>
          Registriere dich, um Veranstaltungen erstellen zu können. Um Tickets
          zu kaufen brauchst du keinen Account.
        </h2>
      </div>
      <div class="content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(signup)">
            <ValidationProvider rules="required|alpha" v-slot="{ errors }">
              <input
                type="text"
                placeholder="Vorname"
                v-model="firstname"
                name="Vorname"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <ValidationProvider rules="required|alpha" v-slot="{ errors }">
              <input
                type="text"
                placeholder="Nachname"
                v-model="lastname"
                name="Nachname"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <ValidationProvider rules="" v-slot="{ errors }">
              <input
                type="text"
                placeholder="Firma (Optional)"
                v-model="company"
                name="Company"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                type="email"
                placeholder="E-Mail"
                v-model="email"
                name="Email"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <ValidationProvider rules="required|min:6" v-slot="{ errors }">
              <input
                type="password"
                placeholder="Passwort"
                v-model="password"
                name="Passwort"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <ValidationProvider
              :rules="{ required: { allowFalse: false } }"
              v-slot="{ errors }"
            >
              <input
                required
                class="checkbox"
                type="checkbox"
                placeholder="terms"
                v-model="terms"
                name="terms"
              />
              Ich habe die
              <a
                href="https://www.iubenda.com/privacy-policy/72827300"
                target="_blank"
                >Datenschutzerklärung</a
              >
              sowie die
              <a href="https://einladung.app/agb" target="_blank">AGB</a>
              gelesen und bin damit einverstanden.
              <p>{{ errors[0] }}</p> </ValidationProvider
            ><br />

            <p class="error" v-if="error">
              <font-awesome-icon icon="exclamation-triangle" />
              {{ error.response.data }}
            </p>

            <button type="submit">Signup</button>
          </form>
        </ValidationObserver>
        <p>
          Du hast schon einen Account?
          <router-link to="/login"
            ><span class="login">Login</span></router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/Nav";
import axios from "axios";
import getAuthToken from "../../js/getAuthToken";

export default {
  name: "Signup",
  components: {
    Nav,
  },
  data() {
    return {
      error: null,
      firstname: null,
      lastname: null,
      company: null,
      email: null,
      password: null,
      terms: null,
    };
  },
  methods: {
    signup: function () {
      this.trackSignup();
      axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/api/auth/register`, {
          firstname: this.firstname,
          lastname: this.lastname,
          company: this.company,
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          console.log(response);
          this.$router.push("organizer/overview");
        })
        .catch((error) => {
          console.log(error.response.data);
          this.error = error;
        });
    },
    trackSignup: function () {
      this.$gtag.event("signup", {
        event_category: "auth",
        value: this.email,
      });
    },
  },
  mounted() {
    //Check if auth token, push them to overview
    if (!getAuthToken.getAuthToken()) {
      console.log("No auth token");
    } else {
      this.$router.push("/organizer/overview");
    }
  },
};
</script>

<style scoped>
.wrapper {
  background-color: var(--yellow);
  min-height: 100vh;
  color: white;
}
.headline {
  max-width: 500px;
}
.content {
  max-width: 500px;
}
input,
.error {
  margin-bottom: 12px;
}

button {
  width: 100%;
  margin-bottom: 12px;
}
.login {
  color: var(--dark-blue);
  text-decoration: underline;
  cursor: pointer;
}
.checkbox {
  width: 12px;
}

a,
link {
  color: white;
}
</style>