<template>
  <div class="landing">
    <div class="header">
      <Nav></Nav>
      <Hero></Hero>
    </div>
    <div class="body">
      <Explainer></Explainer>
      <Instructions></Instructions>
      <Demo></Demo>
      <Features></Features>
      <Pricing></Pricing>
      <Questions></Questions>
      <CTA></CTA>
      <Notify id="notify"></Notify>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Explainer from "../components/landingpage/Explainer";
import Instructions from "../components/landingpage/Instructions";
import Pricing from "../components/landingpage/Pricing";
import Features from "../components/landingpage/Features";
import Notify from "../components/landingpage/Notify";
import Hero from "../components/landingpage/Hero";
import Nav from "../components/Nav";
import Footer from "../components/landingpage/Footer";
import Demo from "../components/landingpage/Demo";
import Questions from "../components/landingpage/Questions";
import CTA from "../components/landingpage/CTA";

export default {
  name: "Landingpage",
  components: {
    Nav,
    Hero,
    Explainer,
    Instructions,
    Pricing,
    Features,
    Notify,
    Footer,
    Demo,
    Questions,
    CTA,
  },
};
</script>

<style scoped>
.landing {
  height: 100vh;
}
.header {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav nav nav"
    "hero hero hero"
    ". arrow .";
  height: 100vh;
}

.yellow {
  color: var(--yellow);
}

.right {
  text-align: right;
}
</style>
