<template>
  <div class="wrapper" id="pricing">
    <div class="headline">
      <h1>Preise</h1>
      <h2>Diese Kosten fallen an</h2>
    </div>
    <div class="content">
      <div class="ticket">
        <h3>Ticket Preis:</h3>
        <div class="input">
          <div class="input-wrapper">
            <input type="text" placeholder="10" v-model="ticketPrice" />
          </div>
          <button><a href="#pricing">Berechnen</a></button>
        </div>
      </div>

      <div class="service">
        <h4>einladung.app</h4>
        <div class="price">
          <h3>3% + €0,20</h3>
          <h3>€{{ calcServiceFees() }}</h3>
        </div>
      </div>

      <div class="card">
        <h4>Kreditkartengebühren</h4>
        <div class="price">
          <h3>1,4% + €0,25</h3>
          <h3>€{{ calcCreditCardFees() }}</h3>
        </div>
      </div>

      <div class="price">
        <h1>Total</h1>
        <h1 class="sum">€{{ calcPayout() }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  data() {
    return {
      ticketPrice: 10,
      serviceFees: 0,
      creditCardFees: 0.25,
      payoutFees: 0.1,
      payout: null,
    };
  },
  methods: {
    calcServiceFees: function () {
      this.serviceFees =
        Math.round((this.ticketPrice * 0.03 + 0.2) * 100) / 100;
      return this.serviceFees;
    },
    calcCreditCardFees: function () {
      this.creditCardFees =
        Math.round((this.ticketPrice * 0.014 + 0.25) * 100) / 100;
      return this.creditCardFees;
    },

    calcPayout: function () {
      return (
        Math.round(
          (this.ticketPrice - this.serviceFees - this.creditCardFees) * 100
        ) / 100
      );
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: var(--yellow);
  color: white;
}

.headline {
  padding-bottom: 0;
}

.content > * {
  margin: 24px 0;
}

.price {
  display: flex;
  justify-content: space-between;
}

.sum {
  text-decoration-line: underline;
  text-decoration-style: double;
}

a {
  color: white;
  text-decoration: none;
}

input {
  padding: 12px 32px;
}

.input {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  align-items: center;
}

.input-wrapper {
  display: inline-block;
  position: relative;
}
.input-wrapper:before {
  content: "€";
  margin: 0 12px;
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: black;
}
</style>