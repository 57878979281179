<template>
  <div>
    <Nav></Nav>
    <div class="wrapper">
      <div class="headline">
        <h1>{{ event.title }}</h1>
        <div class="link">
          <p @click="openLink(event.subdomain)">
            {{ event.subdomain }}.einladung.app
          </p>
          <font-awesome-icon class="icon" icon="external-link-alt" />
        </div>
      </div>

      <div class="content">
        <div class="input">
          <input type="search" v-model="search" placeholder="Suche..." />
          <button><a href="#pricing">Suchen</a></button>
        </div>

        <Attendee
          v-for="attendee in filteredResources"
          :key="attendee._id"
          :attendee="attendee"
        ></Attendee>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Attendee from "../../components/overview/AttendeeListItem";
import Nav from "../../components/Nav";

//import attendee component
export default {
  name: "EventOverview",
  components: {
    Attendee,
    Nav,
  },
  props: [],
  data() {
    return {
      eventId: this.$route.params.eventId,
      event: null,
      tickets: [],
      search: null,
      toolTipText:
        "Gäste können durch Scannen des QR-Codes in der Email, oder manuell eingecheckt werden.",
    };
  },
  mounted() {
    if (this.$route.params.ticketId) {
      this.checkIn();
    } else {
      this.getEvent();
    }
  },
  methods: {
    openLink: function (subdomain) {
      window.open(`https://${subdomain}.einladung.app`);
    },
    getEvent: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/events/eventId/${this.eventId}/attendees`
        )
        .then((response) => {
          this.event = response.data;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
          console.log(err);
        });
    },
    //manuell einchecken durch abfrage des url parameters
    checkIn: function () {
      axios
        .request({
          url: `${process.env.VUE_APP_BACKEND_URL}/api/tickets/checkin/${this.$route.params.ticketId}`,
          method: "put",
          data: { checkedIn: true },
        })
        .then(() => {
          this.getEvent();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
          console.log(err);
        });
    },
  },
  computed: {
    filteredResources() {
      if (this.search) {
        console.log("search");
        return this.event.attendees.filter((attendees) => {
          var columns = attendees.firstname + attendees.lastname;
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => columns.toLowerCase().includes(v));
        });
      } else {
        return this.event.attendees;
      }
    },
  },
};
</script>

<style scoped>
.input {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;
}

a {
  color: white;
  text-decoration: none;
}

button {
  background-color: var(--yellow);
}
</style>