<template>
  <div class="wrapper">
    <div class="headline">
      <h1>Noch Fragen?</h1>
    </div>

    <div class="content">
      <div class="question">
        <font-awesome-icon class="icon yellow" icon="money-bill-wave-alt" />
        <h2>
          Wieso muss ich meine
          <span class="yellow">Kreditkarteninfos</span> hinterlegen?
        </h2>
        <p>
          Der beim Verkauf eines Tickets erziehlte Umsatz wird an die mit dem
          Konto verknüpften Zahlungsmethode in regelmäßigen Abständen
          ausgezahlt.
        </p>
      </div>

      <div class="question">
        <font-awesome-icon class="icon yellow" :icon="['fab', 'cc-stripe']" />
        <h2>
          Was ist
          <span class="yellow">Stripe</span>?
        </h2>
        <p>
          Stripe ist neben PayPal einer der größten Payment-Provider weltweit.
          Dieser verarbeitet Zahlungsprozesse. Dadurch wird gewährleistet, dass
          Transaktionen auf einladung.app sicher und zuverlässig verarbeitet
          werden.
        </p>
      </div>

      <div class="question">
        <font-awesome-icon class="icon yellow" icon="globe-europe" />
        <h2>
          Wie funktioniert der <span class="yellow">Zahlungsvorgang</span>?
        </h2>
        <p>
          Ein Gast wird nach dem Eintragen seiner Persönlichen Informationen,
          bestehend aus Name und E-Mail Adresse, an den Zahlungsdienstleister
          weitegeleitet. Dort wird die Transaktion durch geführt. Nach
          erfolgreichem Kauf eines Tickets wird dieses per E-Mail an den Gast
          gesendet.Kosten
        </p>
      </div>

      <div class="question">
        <font-awesome-icon class="icon yellow" icon="coins" />
        <h2>Wofür fallen <span class="yellow">Kosten</span> an?</h2>
        <p>
          Kosten fallen erst beim Verkauf eines Tickets an. Diese werden beim
          jeweiligen Verkauf automatisch abgezogen. Der Restbetrag wird dem
          Veranstalter gutgeschrieben.
        </p>
      </div>

      <div class="question">
        <font-awesome-icon class="icon yellow" icon="calendar-alt" />
        <h2>Wird ein <span class="yellow">Abonnement</span> benötigt?</h2>
        <p>
          Nein, einladung.app ist ohne Abonnement nutzbar. Kosten fallen in Form
          von Provision beim Verkauf eines Tickets an.
        </p>
      </div>

      <div class="question">
        <font-awesome-icon class="icon yellow" icon="users" />
        <h2>
          <span class="yellow">Für wen</span> ist der Ticket-Service am besten
          geeignet?
        </h2>
        <p>
          Der Service eignet sich am besten für mittelgroße Veranstaltungen wie
          zum Beispiel die von Diskotheken, Straßenfesten, Flohmärken oder
          Parties.
        </p>
      </div>

      <div class="question">
        <font-awesome-icon class="icon yellow" icon="paint-brush" />
        <h2>
          Wie kann ich die
          <span class="yellow">Veranstaltungsseite</span> gestalten?
        </h2>
        <p>
          Gestaltungsoptionen sind begrenzt, lediglich ein Titel und eine
          Beschreibung steht zu verfügung.
        </p>
      </div>

      <div class="question">
        <font-awesome-icon class="icon yellow" icon="clipboard-list" />
        <h2>
          Welche <span class="yellow">Informationen</span> muss ich zu meiner
          Veranstaltung angeben?
        </h2>
        <p>
          Benötigt werden Allgemeine Informationen zu der Veranstaltung, sowie
          Titel und Beschreibung, Datum und Uhrzeit, eine Adresse an der die
          Veranstaltung statt findet und der gewünschte Preis eines Tickets.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Questions",
};
</script>

<style scoped>
.content {
  padding: 0;
}
.question {
  margin-bottom: 40px;
  display: grid;
  grid-template-areas:
    "icon headline"
    "icon text";
  grid-template-columns: 64px 1fr;
  align-items: center;
  gap: 6px 0;
}

.question h1 {
  grid-area: headline;
}

.question p {
  grid-area: text;
}

.icon {
  font-size: 1.6em;
  grid-area: icon;
  place-self: start center;
}

p {
  color: var(--dark-blue-100);
}

.yellow {
  color: var(--yellow);
}

@media screen and (max-width: 500px) {
  .question {
    grid-template-areas:
      "icon headline"
      ". text";
    grid-template-columns: 50px 1fr;
  }
  .icon {
    font-size: 1.6em;
  }
}
</style>