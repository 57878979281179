<template>
  <div class="wrapper">
    <h3>Ticket erfolgreich gekauft!</h3>
    <p>
      Schaue jetzt in deinen Mails nach dem Ticket. Dies kann in seltenen fällen
      auch mal einen Moment dauern.
    </p>
  </div>
</template>

<script>
export default {
  name: "SuccessAlert",
};
</script>

<style scoped>
.wrapper {
  color: var(--yellow);
  background-color: var(--yellow-200) !important;
  border-left: 10px solid var(--yellow);
  border-right: 10px solid var(--yellow-200);
  border-radius: 10px;
  padding: 16px !important;
  position: absolute;
  margin-left: 16px;
  margin-right: 16px;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 10;
}
</style>