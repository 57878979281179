<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto+Mono:wght@400;600;700&display=swap");

:root {
  --yellow: #f4c751;
  --yellow-200: #fff9e8;
  --yellow-rgba: rgba(244, 199, 81, 0.4);
  --dark-blue: #1d1e2c;
  --dark-blue-100: rgba(29, 30, 44, 0.9);
  --dark-blue-200: #61626b;
  --dark-blue-300: #343541;
  --gray-100: rgba(0, 0, 0, 0.3);
  --gray-500: rgba(0, 0, 0, 0.8);
  --gray-300: #a1a1a1;
  --gray-200: #f2f2f2;
  --gray-400: #c1c1c1;
  --red-100: #f95252;
  --red-300: #fa7575;
  --red-200: #ffd8d8;
  --blue: #058ed9;
  --green: #5ac471;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 1.2em;
  color: var(--dark-blue);
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

h1 {
  text-transform: uppercase;
}

h2 {
}
.wrapper {
  padding: 32px 0;
}
.headline {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 16px 24px 16px;
}

.wrapper .headline .link {
  display: flex;
  align-items: center;
  color: var(--blue);
  cursor: pointer;
  display: inline-flex;
}
.wrapper .headline .icon {
  margin-left: 12px;
}
.content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 16px;
}

button {
  color: white;
  background-color: var(--dark-blue);
  border: none;
  border-radius: 7px;
  font-weight: 700;
  font-size: 1rem;
  height: 48px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-transform: uppercase;
  cursor: pointer;
}

input,
textarea {
  font-family: "Roboto Condensed", sans-serif;
  border: none;
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--dark-blue);
  padding: 12px 6px;
  border-radius: 7px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--gray-100);
}

input:focus {
  outline: none;
}

@media screen and (min-width: 601px) {
  body {
  }
}

@media screen and (max-width: 700px) {
  #app {
    font-size: 1.1em;
  }
}
</style>
