<template>
  <div class="wrapper">
    <div class="headline">
      <h2>
        Einladung.app ist dein Ticket-Service für
        <span>Liveerlebnisse</span>!
      </h2>
      <br />
      <p>
        Für das Eigene Event werben, Gäste verwalten und Einnahmen durch
        Ticketverkäufe generieren. <br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Explainer",
};
</script>

<style scoped>
.wrapper {
  background-color: var(--yellow);
  color: white;
}

h1 {
  margin-bottom: 24px;
}

.headline {
  padding: 24px 16px;
}

span {
  text-decoration: underline;
}
</style>