var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Nav'),_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.signup)}}},[_c('ValidationProvider',{attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstname),expression:"firstname"}],attrs:{"type":"text","placeholder":"Vorname","name":"Vorname"},domProps:{"value":(_vm.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstname=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastname),expression:"lastname"}],attrs:{"type":"text","placeholder":"Nachname","name":"Nachname"},domProps:{"value":(_vm.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastname=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],attrs:{"type":"text","placeholder":"Firma (Optional)","name":"Company"},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","placeholder":"E-Mail","name":"Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","placeholder":"Passwort","name":"Passwort"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.terms),expression:"terms"}],staticClass:"checkbox",attrs:{"required":"","type":"checkbox","placeholder":"terms","name":"terms"},domProps:{"checked":Array.isArray(_vm.terms)?_vm._i(_vm.terms,null)>-1:(_vm.terms)},on:{"change":function($event){var $$a=_vm.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.terms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.terms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.terms=$$c}}}}),_vm._v(" Ich habe die "),_c('a',{attrs:{"href":"https://www.iubenda.com/privacy-policy/72827300","target":"_blank"}},[_vm._v("Datenschutzerklärung")]),_vm._v(" sowie die "),_c('a',{attrs:{"href":"https://einladung.app/agb","target":"_blank"}},[_vm._v("AGB")]),_vm._v(" gelesen und bin damit einverstanden. "),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),(_vm.error)?_c('p',{staticClass:"error"},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-triangle"}}),_vm._v(" "+_vm._s(_vm.error.response.data)+" ")],1):_vm._e(),_c('button',{attrs:{"type":"submit"}},[_vm._v("Signup")])],1)]}}])}),_c('p',[_vm._v(" Du hast schon einen Account? "),_c('router-link',{attrs:{"to":"/login"}},[_c('span',{staticClass:"login"},[_vm._v("Login")])])],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Erstelle einen Account")]),_c('h2',[_vm._v(" Registriere dich, um Veranstaltungen erstellen zu können. Um Tickets zu kaufen brauchst du keinen Account. ")])])}]

export { render, staticRenderFns }