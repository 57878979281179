<template>
  <div>
    <Nav></Nav>

    <div class="wrapper">
      <div class="headline">
        <h1>Logge dich ein</h1>
        <h2>
          Logge dich ein, um Veranstaltungen erstellen zu können. Um Tickets zu
          kaufen brauchst du keinen Account.
        </h2>
      </div>
      <div class="content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(login)">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                type="email"
                placeholder="E-Mail"
                v-model="email"
                name="email"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                type="password"
                placeholder="Passwort"
                v-model="password"
                name="password"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <p class="error" v-if="error">
              <font-awesome-icon icon="exclamation-triangle" />
              {{ error.response.data }}
            </p>

            <button type="submit">Login</button>
          </form>
        </ValidationObserver>

        <p>
          Du hast noch keinen Account?
          <router-link to="signup"
            ><span class="signup">Signup</span></router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/Nav";
import axios from "axios";
import getAuthToken from "../../js/getAuthToken";

export default {
  name: "Login",
  components: {
    Nav,
  },
  data() {
    return {
      error: null,
      email: null,
      password: null,
    };
  },
  methods: {
    login: function () {
      this.trackLogin();
      axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/api/auth/login`, {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          console.log(response);
          console.log(getAuthToken.getAuthToken());
          this.$router.push("organizer/overview");
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
        });
    },
    trackLogin: function () {
      this.$gtag.event("login", {
        event_category: "auth",
        value: this.email,
      });
    },
  },
  mounted() {
    //Check if auth token, push them to overview
    if (getAuthToken.getAuthToken()) this.$router.push("/organizer/overview");
  },
};
</script>

<style scoped>
.wrapper {
  background-color: var(--yellow);
  min-height: 100vh;
  color: white;
}
.headline {
  max-width: 500px;
}
.content {
  max-width: 500px;
}
input,
.error {
  margin-bottom: 12px;
}

button {
  width: 100%;
  margin-bottom: 12px;
}
.signup {
  color: var(--dark-blue);
  text-decoration: underline;
  cursor: pointer;
}
</style>