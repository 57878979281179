var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav"},[_c('div',{staticClass:"logo"},[(
        _vm.pathName == 'landingpage' ||
        _vm.pathName == 'login' ||
        _vm.pathName == 'signup'
      )?_c('p',{staticClass:"font",on:{"click":function($event){return _vm.$router.push('/')}}},[_c('font-awesome-icon',{staticClass:"icon yellow",attrs:{"icon":"paper-plane"}}),_vm._v(" einladung"),_c('span',{staticClass:"yellow"},[_vm._v(".")]),_vm._v("app ")],1):(_vm.pathName == 'event')?_c('p',{staticClass:"font",on:{"click":function($event){return _vm.openLandingPage()}}},[_c('font-awesome-icon',{staticClass:"icon yellow",attrs:{"icon":"paper-plane"}}),_vm._v(" einladung"),_c('span',{staticClass:"yellow"},[_vm._v(".")]),_vm._v("app ")],1):_c('p',{staticClass:"font",on:{"click":function($event){return _vm.$router.push('/organizer/overview')}}},[_c('font-awesome-icon',{staticClass:"icon yellow",attrs:{"icon":"paper-plane"}}),_vm._v(" einladung"),_c('span',{staticClass:"yellow"},[_vm._v(".")]),_vm._v("app ")],1)]),_c('div',{staticClass:"links"},[(_vm.pathName == 'landingpage')?_c('p',{on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v(" Login ")]):_vm._e(),(
        _vm.pathName != 'landingpage' &&
        _vm.pathName != 'signup' &&
        _vm.pathName != 'login' &&
        _vm.pathName != 'event'
      )?_c('p',{staticClass:"logout",on:{"click":function($event){return _vm.$router.push('/logout')}}},[_vm._v(" Logout ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }