<template>
  <div class="wrapper">
    <div class="headline">
      <h1>Features</h1>
      <h2>Mit diesen Funktionen zum erfolgreichen Event</h2>
    </div>

    <div class="content">
      <div>
        <font-awesome-icon class="icon yellow" icon="check" />
        <h3>Veranstaltung erstellen</h3>
      </div>
      <div>
        <font-awesome-icon class="icon yellow" icon="check" />
        <h3>Tickets verkaufen</h3>
      </div>
      <div>
        <font-awesome-icon class="icon yellow" icon="check" />
        <h3>Gäste verwalten</h3>
      </div>
      <div>
        <font-awesome-icon class="icon red" icon="times" />
        <h3 class="crossed">Discovery Page</h3>
      </div>
      <div>
        <font-awesome-icon class="icon red" icon="times" />
        <h3 class="crossed">Page Builder</h3>
      </div>
      <div>
        <font-awesome-icon class="icon red" icon="times" />
        <h3 class="crossed">Analytics</h3>
      </div>
      <div>
        <font-awesome-icon class="icon red" icon="times" />
        <h3 class="crossed">Ticket Tiers (Standart, Premium)</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
};
</script>

<style scoped>
.wrapper .content > * {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 24px;
  margin: 12px 0;
}

.icon {
  font-size: 1.5rem;
  place-self: center;
}

.yellow {
  color: var(--yellow);
}

.red {
  color: var(--red-100);
}

.crossed {
  color: var(--gray-300);
}
</style>