import LandingPage from "../sites/LandingPage.vue"
import OrganizerOverview from "../sites/organizer/Overview.vue"
import Event from "../sites/Event.vue"
import CreateEvent from "../sites/organizer/CreateEvent.vue"
import Login from "../sites/auth/Login.vue"
import Signup from "../sites/auth/Signup.vue"
import Logout from "../sites/auth/Logout.vue"
import EventOverview from "../sites/organizer/EventOverview.vue"
import AGB from "../sites/legal/AGB.vue"
import Impressum from "../sites/legal/Impressum.vue"

var routes = []

if (process.env.NODE_ENV == "production") {
    routes = [
        {
            path: "/", component: LandingPage, name: "landingpage", beforeEnter: (to, from, next) => {
                var subdomain = window.location.host.split(".")[0]
                var domain = process.env.VUE_APP_BASE_URL_DOMAIN
                var pageToLoad = "event"

                if (subdomain !== domain && to.name !== pageToLoad) {
                    next({ name: pageToLoad, params: { subdomain: subdomain, success: to.query.success } })
                } else {
                    next()
                }
            },
        },
        { path: "/organizer/overview", component: OrganizerOverview, name: "organizerOverview" },
        { path: "/", name: "event", component: Event },
        { path: "/organizer/event/create", name: "createevent", component: CreateEvent },
        { path: "/organizer/event/:eventId", name: "eventoverview", component: EventOverview },
        { path: "/organizer/event/:eventId/checkin/:ticketId", name: "checkin", component: EventOverview },
        { path: "/login", name: "login", component: Login },
        { path: "/signup", name: "signup", component: Signup },
        { path: "/logout", name: "logout", component: Logout },
        { path: "/agb", name: "agb", component: AGB },
        { path: "/impressum", name: "impressum", component: Impressum },
    ]
} else if (process.env.NODE_ENV == "development") {
    routes = [
        { path: "/", component: LandingPage, name: "landingpage" },
        { path: "/organizer/overview", component: OrganizerOverview },
        { path: "/organizer/event/create", name: "createevent", component: CreateEvent },
        { path: "/organizer/event/:eventId", name: "eventoverview", component: EventOverview },
        { path: "/organizer/event/:eventId/checkin/:ticketId", name: "checkin", component: EventOverview },
        { path: "/event/:subdomain", name: "event", component: Event },
        { path: "/login", name: "login", component: Login },
        { path: "/signup", name: "signup", component: Signup },
        { path: "/logout", name: "logout", component: Logout },
        { path: "/agb", name: "agb", component: AGB },
        { path: "/impressum", name: "impressum", component: Impressum },
    ]
}

export { routes }


