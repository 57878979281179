<template>
  <div></div>
</template>

<script>
import axios from "axios";
export default {
  name: "Logout",
  mounted() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/api/auth/logout`)
      .then(() => {
        this.$router.push("/");
      })
      .catch((error) => {
        console.log(error);
        this.error = error;
      });
  },
};
</script>