<template>
  <div class="container">
    <div class="attendee grid">
      <input
        class="checkbox"
        type="checkbox"
        v-model="checkedIn"
        @click="checkIn()"
      />

      <div class="name" v-bind:class="{ checkedIn: checkedIn }">
        <h3>{{ attendee.firstname }} {{ attendee.lastname }}</h3>
        <p>{{ attendee.email }}</p>
      </div>
      <h3 class="code" v-bind:class="{ checkedIn: checkedIn }">
        {{ attendee.ticket.code }}
      </h3>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Attendee",
  props: ["attendee"],
  components: {},
  data() {
    return {
      checkedIn: this.attendee.ticket.checkedIn,
    };
  },
  methods: {
    checkIn: function () {
      axios
        .request({
          url: `${process.env.VUE_APP_BACKEND_URL}/api/tickets/checkin/${this.attendee.ticket._id}`,
          method: "put",
          data: { checkedIn: !this.checkedIn },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 45px 10fr 1fr;
  gap: 6px;
  justify-items: start;
  align-items: center;
}

.attendee {
  background-color: var(--gray-200);
  border-radius: 7px;
  padding: 12px 6px;
  margin-bottom: 12px;
}

.attendee:hover {
  background-color: var(--gray-200);
}

.checkedIn {
  color: var(--gray-300);
  text-decoration: line-through;
}

input {
  box-shadow: none !important;
  cursor: pointer;
  width: auto;
}

.checkbox {
  margin: 16px 0;
  place-self: center;
}

.name p {
}

.code {
  place-self: center;
  background-color: var(--gray-400);
  border-radius: 5px;
  padding: 4px 8px;
}
</style>