var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('Nav'),(_vm.success)?_c('SuccessAlert'):_vm._e(),_c('div',{staticClass:"hero"},[_c('div',{staticClass:"headline"},[_c('h1',[_vm._v(_vm._s(_vm.event.title))]),_c('h2',[_vm._v(_vm._s(_vm.event.description))]),_c('h4',[_vm._v("Veranstaltet von")]),(_vm.organizer.company)?_c('h3',[_vm._v(_vm._s(_vm.organizer.company))]):_c('h3',[_vm._v(_vm._s(_vm.organizer.firstname)+" "+_vm._s(_vm.organizer.lastname))])])]),_c('div',{staticClass:"date"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"timeInfo"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"clock"}}),_c('h2',{staticClass:"title"},[_vm._v("Datum und Uhrzeit")]),_c('div',{staticClass:"info"},[_c('h3',[_vm._v("Ab "+_vm._s(_vm.event.time)+" Uhr")]),_c('h3',[_vm._v("Am "+_vm._s(_vm.formatDate))])])],1),_c('div',{staticClass:"dateInfo"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"map-marker-alt"}}),_c('h2',{staticClass:"title"},[_vm._v("Veranstaltungsort")]),_c('div',{staticClass:"info"},[_c('h3',[_vm._v(_vm._s(_vm.event.address.street)+" "+_vm._s(_vm.event.address.number))]),_c('h3',[_vm._v(_vm._s(_vm.event.address.city)+" "+_vm._s(_vm.event.address.zip))])])],1)])]),_c('iframe',{staticStyle:{"border":"0"},attrs:{"width":"100%","height":"400px","loading":"lazy","allowfullscreen":"","src":_vm.src}}),_c('div',{staticClass:"takepart"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v("JETZT TEILNEHMEN!")]),_c('h2',[_vm._v("Trage deine Kontaktdaten in das Eingabeformular ein")]),_c('h4',[_vm._v(" Bei korrekter Dateneingabe wird auf unsere Partnerplattform Stripe.com weitergeleitet. Sobald die Zahlung erfolgreich Abgeschlossen wurde, wird das Ticket an die unten eingetragene E-Mail Adresse geschickt. ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.buyTicket)}}},[_c('ValidationProvider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstname),expression:"firstname"}],attrs:{"type":"text","placeholder":"Vorname","name":"firstname"},domProps:{"value":(_vm.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstname=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastname),expression:"lastname"}],attrs:{"type":"text","placeholder":"Nachname","name":"lastname"},domProps:{"value":(_vm.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastname=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","placeholder":"E-Mail","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('button',{attrs:{"type":"submit"}},[(!_vm.loading)?_c('span',[_vm._v("Ticket kaufen "+_vm._s(_vm.event.price / 100)+" €")]):_c('clip-loader',{attrs:{"color":_vm.color}})],1)],1)]}}])})],1)]),_c('CTA'),_c('Footer',{staticClass:"footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }