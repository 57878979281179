<template>
  <div class="wrapper">
    <Nav></Nav>
    <SuccessAlert v-if="success"></SuccessAlert>
    <div class="hero">
      <div class="headline">
        <h1>{{ event.title }}</h1>
        <h2>{{ event.description }}</h2>
        <h4>Veranstaltet von</h4>
        <h3 v-if="organizer.company">{{ organizer.company }}</h3>
        <h3 v-else>{{ organizer.firstname }} {{ organizer.lastname }}</h3>
      </div>
    </div>

    <div class="date">
      <div class="content">
        <div class="timeInfo">
          <font-awesome-icon class="icon" icon="clock" />
          <h2 class="title">Datum und Uhrzeit</h2>
          <div class="info">
            <h3>Ab {{ event.time }} Uhr</h3>
            <h3>Am {{ formatDate }}</h3>
          </div>
        </div>

        <div class="dateInfo">
          <font-awesome-icon class="icon" icon="map-marker-alt" />
          <h2 class="title">Veranstaltungsort</h2>
          <div class="info">
            <h3>{{ event.address.street }} {{ event.address.number }}</h3>
            <h3>{{ event.address.city }} {{ event.address.zip }}</h3>
          </div>
        </div>
      </div>
    </div>

    <iframe
      width="100%"
      height="400px"
      style="border: 0"
      loading="lazy"
      allowfullscreen
      :src="src"
    >
    </iframe>

    <div class="takepart">
      <div class="content">
        <h1>JETZT TEILNEHMEN!</h1>
        <h2>Trage deine Kontaktdaten in das Eingabeformular ein</h2>
        <h4>
          Bei korrekter Dateneingabe wird auf unsere Partnerplattform Stripe.com
          weitergeleitet. Sobald die Zahlung erfolgreich Abgeschlossen wurde,
          wird das Ticket an die unten eingetragene E-Mail Adresse geschickt.
        </h4>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(buyTicket)">
            <ValidationProvider rules="required|min:2" v-slot="{ errors }">
              <input
                type="text"
                placeholder="Vorname"
                v-model="firstname"
                name="firstname"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <ValidationProvider rules="required|min:2" v-slot="{ errors }">
              <input
                type="text"
                placeholder="Nachname"
                v-model="lastname"
                name="lastname"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <ValidationProvider rules="required|min:4" v-slot="{ errors }">
              <input
                type="email"
                placeholder="E-Mail"
                v-model="email"
                name="email"
              />
              <p>{{ errors[0] }}</p>
            </ValidationProvider>

            <button type="submit">
              <span v-if="!loading"
                >Ticket kaufen {{ event.price / 100 }} €</span
              >
              <clip-loader v-else :color="color"></clip-loader>
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <CTA></CTA>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import axios from "axios";
import Nav from "../components/Nav";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import SuccessAlert from "../components/alerts/SuccessAlert.vue";
import Footer from "../components/landingpage/Footer.vue";
import CTA from "../components/landingpage/CTA.vue";

export default {
  name: "Event",
  components: { Nav, ClipLoader, SuccessAlert, Footer, CTA },
  data() {
    return {
      firstname: null,
      lastname: null,
      email: null,
      event: null,
      organizer: null,
      src: null,
      loading: false,
      color: "#ffffff",
      size: "30px",
      success: null,
    };
  },
  methods: {
    buyTicket: function () {
      this.loading = true;
      axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/api/stripe/buy-ticket`, {
          subdomain: this.$route.params.subdomain,
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
        })
        .then((response) => {
          window.open(response.data.sessionUrl, "_self");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getOrganizer: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/organizers/eventId/${this.event._id}`
        )
        .then((response) => {
          console.log(response.data);
          this.organizer = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    startConfetti: function () {
      this.$confetti.start({
        particles: [
          {
            type: "rect",
            size: 8,
            defaultDropRate: 15,
            particlesPerFrame: 1,
            windSpeedMax: 0,
            colors: [
              "#f4c751",
              "#058ed9",
              "#5ac471",
              "#f95252",
              "#ffd8d8",
              "#fff9e8",
            ],
          },
        ],
      });
    },
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_BACKEND_URL}/api/events/subdomain/${this.$route.params.subdomain}`
      )
      .then((response) => {
        console.log(response.data);
        this.event = response.data;
        this.getOrganizer();
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(this.$route.params);
    if (this.$route.params.success) {
      this.success = true;
      this.startConfetti();
      window.setTimeout(() => this.$confetti.stop(), 3000);
    }
  },
  updated() {
    this.src = `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&zoom=15&q=${this.event.address.street}+${this.event.address.number},${this.event.address.city}+${this.event.address.zip}`;
  },
  computed: {
    formatDate: function () {
      const date = new Date(this.event.date);
      return date.toLocaleDateString();
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 0;
  background-color: var(--yellow);
}

.headline {
  position: relative;
  color: white;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}
.hero {
  padding: 32px 0;
  position: relative;
}
.hero h4 {
  color: var(--yellow);
}
.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/hero.jpg");
  filter: grayscale(100%);
  background-size: cover;
  background-position: center;
}
.date {
  padding: 32px 0;
  background-color: var(--dark-blue);
}
.date .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  color: white;
}

.timeInfo,
.dateInfo {
  display: grid;
  grid-template-areas:
    "icon title"
    ". info";
  grid-template-columns: 30px 1fr;
  gap: 4px 12px;
}

.icon {
  grid-area: icon;
  place-self: center;
  color: var(--yellow);
  font-size: 1.6rem;
}

.title {
  grid-area: title;
}

.info {
  grid-area: info;
}
.takepart {
  padding: 32px 0;
  background-color: var(--yellow);
  color: white;
}

.takepart .content > * {
  margin-bottom: 6px;
}
input {
  margin-bottom: 12px;
}
button {
  width: 100%;
}

@media screen and (max-width: 601px) {
  .date .content {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
</style>