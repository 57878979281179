<template>
  <div class="wrapper">
    <font-awesome-icon
      class="icon"
      icon="exclamation-triangle"
    ></font-awesome-icon>
    <div>
      <h3>Kreditkarte benötigt</h3>
      <p>
        Bevor du ein Event erstellen kannst benötigen wir deine
        Kreditkarteninformationen. Dazu leiten wir dich zu unserem
        Paymentprovider weiter. Dies kann einen Moment dauern.
      </p>
    </div>
    <button @click="startOnboarding()">
      <span v-if="!loading"> Hinzufügen </span>
      <clip-loader v-else :color="color" :size="size"></clip-loader>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "StripeOnboardingAlert",
  data() {
    return {
      loading: false,
      color: "#ffffff",
      size: "30px",
    };
  },
  components: {
    ClipLoader,
  },
  methods: {
    startOnboarding: function () {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/stripe/onboarding`)
        .then((response) => {
          window.open(response.data.url, "_self");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: auto 1fr 200px;
  gap: 12px;
  align-items: center;
  color: var(--red-100);
  background-color: var(--red-200);
  border-left: 10px solid var(--red-100);
  border-right: 10px solid var(--red-200);
  border-radius: 10px;
  padding: 16px;
}

.icon {
  font-size: 2rem;
  margin: 0 4px 0 0;
}

button {
  background-color: var(--red-100);
  width: 100%;
}

@media screen and (max-width: 715px) {
  .wrapper {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .icon {
    display: none;
  }

  button {
    margin: 12px 0 0 0;
  }
}
</style>