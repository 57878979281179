<template>
  <div class="wrapper">
    <div class="headline">
      <h1>Jetzt Veranstaltungen erstellen!</h1>
      <h3>
        Ganz einfach einen Account erstellen, Zahlungsmethode hinterlegen
        und Veranstaltungen erstellen.
      </h3>
      <button @click="openLink()">Veranstaltungen Erstellen</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CTA",
  methods: {
    openLink: function () {
      if (this.$route.name == "event") {
        this.$router.push("/");
      } else if (this.$route.name == "landingpage") {
        this.$router.push("/signup");
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  text-align: center;
  background-color: var(--red-100) !important;
  color: white !important;
  padding: 32px 0 !important;
}

.link {
  color: white;
  text-decoration: none;
}

button {
  background-color: var(--red-300);
}

h3 {
  margin-bottom: 24px;
}
</style>