<template>
  <div class="wrapper">
    <div class="headline">
      <h1>Updates, Angebote, News?</h1>
      <h3>
        Jetzt in den Verteiler eintragen und die wichtigsten Infos per E-Mail
        erhalten!
      </h3>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit="handleSubmit(send)">
          <ValidationProvider v-slot="{ errors }">
            <input
              type="email"
              placeholder="E-Mail"
              v-model="email"
              name="email"
            />
            <p class="error" v-if="errors.length != 0">
              <font-awesome-icon icon="exclamation-triangle" />
              {{ errors[0] }}
            </p>
          </ValidationProvider>
          <button type="submit">Senden</button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "wrapper",
  data() {
    return {
      email: null,
    };
  },
  methods: {
    send: function () {
      this.trackNotify();
      axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/api/mailchimp/contacts`, {
          email: this.email,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    trackNotify: function () {
      this.$gtag.event("submit_form", {
        event_category: "forms",
        event_label: "notify email list",
        value: this.email,
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  color: white;
  background-color: var(--yellow);
  text-align: center;
  display: grid;
  align-items: center;
}

.wrapper button {
  color: white;
  background-color: var(--dark-blue);
  border: none;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  height: 48px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-transform: uppercase;
}

form {
  margin: 24px 0 0 0;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
}

.wrapper .wrapper a {
  color: white;
}

.error {
  text-align: left;
}
</style>