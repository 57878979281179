<template>
  <div class="wrapper">
    <div class="headline">
      <h1>Und so geht's</h1>
      <h2>In 8 schnellen Schritten zum verkauften Ticket</h2>
    </div>
    <div class="content">
      <!-- Create Account -->
      <div class="1">
        <p class="number">1</p>
        <div class="text">
          <div>
            <h2><span class="yellow">Account</span> erstellen</h2>
            <p>
              Nur Veranstalter brauchen einen registrierten Account. Gäste
              können ohne Account die jeweilige Eventseite besuchen und Tickets
              kaufen.
            </p>
          </div>
        </div>
        <img src="../../assets/explainer-svg/secure.svg" alt="" />
      </div>

      <!-- Create Invitation -->
      <div class="2">
        <p class="number">2</p>
        <div class="text">
          <h2>Mit <span class="yellow">Stripe</span> verbinden</h2>
          <p>
            Die Transaktionen werden den Profis überlassen. Stripe.com ist einer
            der größten Payment-Providern weltweit und kümmert sich um eine
            sichere und zuverlässige Zahlungsabwicklung.
          </p>
        </div>
        <img src="../../assets/explainer-svg/stripe.svg" alt="" />
      </div>

      <!-- Unique Links -->
      <div class="3">
        <p class="number">3</p>
        <div class="text">
          <div>
            <h2><span class="yellow">Veranstaltung</span> erstellen</h2>
            <p>
              Die wichtigsten Informationen eingeben und die Veranstaltung
              veröffentlichen. Diese ist dann über ihre eigene URL zu erreichen.
            </p>
          </div>
        </div>
        <img src="../../assets/explainer-svg/website.svg" alt="" />
      </div>

      <!-- Share Link -->
      <div class="4">
        <p class="number">4</p>
        <div class="text">
          <div>
            <h2><span class="yellow">Link</span> teilen</h2>
            <p>
              Der URL-Link kann ganz einfach per Copy and Paste geteilt werden.
              Privat über Whatsapp, öffentlich auf Instgram oder als Print auf
              einem Plakat.
            </p>
          </div>
        </div>
        <img src="../../assets/explainer-svg/influencer.svg" alt="" />
      </div>

      <!-- Manage guests -->
      <div class="5">
        <p class="number">5</p>
        <div class="text">
          <h2><span class="yellow">Zahlungen</span> empfangen</h2>
          <p>
            Gäste erfahren auf der Veranstaltungseite die wichtigsten Infos
            eines Events und können sich mit kleinstem Aufwand Tickets kaufen.
          </p>
        </div>
        <img src="../../assets/explainer-svg/moneyrain.svg" alt="" />
      </div>

      <!-- Ticket per Mail -->
      <div class="6">
        <p class="number">6</p>
        <div class="text">
          <h2><span class="yellow">Ticket</span> per E-Mail</h2>
          <p>
            Tickets werden ausschließlich online per E-Mail verschickt. Nach dem
            Kauf eines Tickets ist dieses bereits innerhalb weniger Sekunden im
            Postfach des Gasts.
          </p>
        </div>
        <img src="../../assets/explainer-svg/mail.svg" alt="" />
      </div>

      <!-- Manage Invitations -->
      <div class="7">
        <p class="number">7</p>
        <div class="text">
          <div>
            <h2><span class="yellow">Gäste</span> verwalten</h2>
            <p>
              Das Dasboard bietet einen Überblick über die teilnehmenden Gäste.
            </p>
          </div>
        </div>
        <img src="../../assets/explainer-svg/guests.svg" alt="" />
      </div>

      <!-- Receive Payment -->
      <div class="8">
        <p class="number">8</p>
        <div class="text">
          <h2><span class="yellow">Einchecken</span></h2>
          <p>
            Am Einlass des Events können Gäste ihr E-Mail Ticket vorzeigen,
            welches per QR-Code gescannt wird. Alternativ kann auch manuell
            eingecheckt werden.
          </p>
        </div>
        <img src="../../assets/explainer-svg/ticket.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "content",
};
</script>

<style scoped>
.content > * {
  display: grid;
  gap: 24px;
  grid-template-columns: 0.5fr 400px 0.5fr;
  grid-template-areas: "number text img";
  justify-content: space-between;
  margin-bottom: 96px;
}

.content div:nth-child(2n) {
  grid-template-areas: "img text number";
}

.content .number {
  grid-area: number;
  place-self: center end;
}

.content div:nth-child(2n) .number {
  place-self: center start;
}

.content .text {
  grid-area: text;
  place-self: center start;
  max-width: 600px;
}
.content div:nth-child(2n) .text {
  text-align: right;
  place-self: center end;
}

.content img {
  grid-area: img;
  place-self: center start;
}

.content div:nth-child(2n) img {
  place-self: center end;
}

.content .number {
  height: 60px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--dark-blue);
  font-size: 2rem;
}

.content img {
  height: 120px;
}

.content p {
  color: var(--dark-blue-100);
}

.yellow {
  color: var(--yellow);
}

@media screen and (max-width: 900px) {
  .content > * {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "number img"
      "text text";
    margin: 0 auto 48px auto;
    max-width: 600px;
  }

  .content div:nth-child(2n) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "img number"
      "text text";
  }

  .content .number {
    grid-area: number;
    place-self: end;
  }

  .content div:nth-child(2n) .number {
    place-self: end;
  }

  .content .text {
    grid-area: text;
    place-self: normal;
  }
  .content div:nth-child(2n) .text {
    text-align: right;
  }

  .content img {
    grid-area: img;
    place-self: end start;
  }

  .content div:nth-child(2n) img {
    place-self: end end;
  }
}
</style>