<template>
  <div class="wrapper">
    <div class="headline">
      <h1>Wie sieht eine Eventseite aus?</h1>
      <h3>Jetzt auf den Link klicken um die Muster-Eventseite zu besuchen</h3>
      <div class="link">
        <p @click="openDemoEvent()">demo.einladung.app</p>
        <font-awesome-icon class="icon" icon="external-link-alt" />
      </div>
    </div>
    <div class="content">
      <h1>Wie sieht ein Ticket aus?</h1>
      <h3>
        Jetzt E-Mail Adresse eingeben um sofort ein kostenloses Muster-Ticket zu
        empfangen
      </h3>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit="handleSubmit(sendDemoTicket)">
          <ValidationProvider v-slot="{ errors }">
            <input
              type="email"
              placeholder="E-Mail"
              v-model="email"
              name="email"
            />
            <p class="error" v-if="errors.length != 0">
              <font-awesome-icon icon="exclamation-triangle" />
              {{ errors[0] }}
            </p>
          </ValidationProvider>
          <button type="submit">Senden</button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Demo",
  data() {
    return {
      email: null,
    };
  },
  methods: {
    openDemoEvent() {
      window.open("https://demo.einladung.app");
    },
    sendDemoTicket: function () {
      this.trackDemoTicket();
      //Demo Ticket
      axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL}/api/events/send-demo-ticket`,
          {
            email: this.email,
          }
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

      //Add Email to Mailchimp
      axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/api/mailchimp/contacts`, {
          email: this.email,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    trackDemoTicket: function () {
      this.$gtag.event("submit_form", {
        event_category: "demo",
        event_label: "demo ticket",
        value: this.email,
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  color: white;
  background-color: var(--yellow);
}

.link {
  margin: 12px 0;
  padding: 4px 8px;
  background-color: white;
  border-radius: 7px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

form {
  margin: 24px 0 0 0;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
}
</style>