<template>
  <div>
    <Nav></Nav>

    <div class="wrapper">
      <div class="headline">
        <div class="title">
          <h1>Veranstaltungen</h1>
          <div class="link" @click="openDashboard()">
            <p>Open Stripe Dasboard</p>
            <font-awesome-icon class="icon" icon="external-link-alt" />
          </div>
        </div>
        <button @click="createEvent()">
          <font-awesome-icon class="icon" icon="plus"></font-awesome-icon>
          Create event
        </button>
      </div>

      <div class="content">
        <StripeOnboardingAlert
          v-if="stripeOnboardingAlert"
        ></StripeOnboardingAlert>

        <event-list-item
          v-for="event in events"
          :key="event._id"
          :event="event"
        ></event-list-item>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventListItem from "../../components/overview/EventListItem.vue";
import Nav from "../../components/Nav.vue";
import StripeOnboardingAlert from "../../components/alerts/StripeOnboardingAlert.vue";

export default {
  name: "Overview",
  data() {
    return {
      events: null,
      dashboardLink: "",
      stripeOnboardingAlert: false,
    };
  },
  components: {
    EventListItem,
    Nav,
    StripeOnboardingAlert,
  },
  methods: {
    createEvent: function () {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/organizers/has-connected`)
        .then(() => {
          this.$router.push("/organizer/event/create");
        })
        .catch((error) => {
          this.stripeOnboardingAlert = true;
          console.log(error);
        });
    },
    openDashboard: function () {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/stripe/dashboard`)
        .then((response) => {
          window.open(response.data.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/api/events`)
      .then((response) => {
        this.events = response.data;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$router.push("/login");
        }
        console.log(error);
      });
  },
};
</script>

<style scoped>
.headline {
  display: grid;
  grid-template-columns: 1fr auto;
}

button {
  background-color: var(--yellow);
  padding: 0 12px;
}

.link {
  margin: 4px 0;
}

@media screen and (max-width: 601px) {
  .headline {
    grid-template-columns: 1fr;
  }

  button {
    margin: 12px 0 0 0;
  }
}
</style>